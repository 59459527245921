(function () {
  const SELECTOR_MENU_TOGGLE = '.menu-toggle, .navigation-toggle';
  const CLASS_NAME_OPEN = 'is-open';

  function isMenuOpen(menu) {
    return menu.classList.contains(CLASS_NAME_OPEN);
  }

  function openMenu(menu, button) {
    button.setAttribute('aria-expanded', 'true');
    menu.classList.add(CLASS_NAME_OPEN);
  }

  function closeMenu(menu, button) {
    button.setAttribute('aria-expanded', 'false');
    menu.classList.remove(CLASS_NAME_OPEN);
  }

  function toggleMenu(menu, button) {
    if (isMenuOpen(menu)) {
      closeMenu(menu, button);
    } else {
      openMenu(menu, button);
    }
  }

  function handleButtonClick(event) {
    const button = event.target.closest(SELECTOR_MENU_TOGGLE);
    const menu = button.nextElementSibling;

    toggleMenu(menu, button);
  }

  for (const button of document.querySelectorAll(SELECTOR_MENU_TOGGLE)) {
    button.addEventListener('click', handleButtonClick);
  }

})();
