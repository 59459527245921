
document.addEventListener("DOMContentLoaded", function() {
    const headers = document.querySelectorAll('.accordion-header');

    headers.forEach(header => {
        header.classList.add('js-accordion-enabled');
        header.addEventListener('click', function() {
            const body = this.nextElementSibling;
            const allBodies = document.querySelectorAll('.accordion-body');
	        
            this.classList.toggle('js-accordion-open');
            

            allBodies.forEach(b => {
                if (b !== body) {
                    b.classList.remove('js-accordion-open');
                }
            });
            body.classList.toggle('js-accordion-open');
            
        });
    });
});

